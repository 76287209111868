/* Container */
.fv_language-selector {
    position: relative;
    width: 180px;
    /* Adjust width to fit both icon and text */
    font-family: 'Inter', sans-serif;
}

/* Dropdown */
.fv_language-dropdown {
    position: relative;
}

/* Toggle Button */
.fv_language-toggle {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    /* Slightly rounded, not circular */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.fv_language-toggle:hover {
    border-color: #888;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.fv_language-toggle .fi {
    margin-left: 10px;
    font-size: 1.2em;
}

.fv_language-icon-body {
    margin-right: 5px;
    padding-right: 6px;
    border-right: 1px solid #EBEBEB;
}

.fv_language-icon {
    font-size: 1.2em;
}

.fv_language-selected {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
    font-weight: 500;
}

/* Dropdown Menu */
.fv_language-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-top: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    padding-left: 0px;
    animation: fv_language-fadeIn 0.3s ease, fv_language-scaleIn 0.2s ease;
}

.fv_language-item {
    padding: 12px 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.fv_language-item .fi {
    margin-right: 12px;
    font-size: 1.2em;
}

.fv_language-item:hover {
    background-color: #f0f0f0;
    color: #333;
}

.fv_language-option-label {
    flex-grow: 1;
    font-weight: 500;
}